import axios from "axios";

export const server_url = "https://ncpscserver.tarikul.dev";
// export const server_url = "http://localhost:8080";

export const classData = [
  {
    name: "ten",
    sections: ["kadam", "palash", "shapla", "bokul"],
  },

  {
    name: "eleven",
    sections: ["omega", "delta", "pi", "sigma"],
  },
  {
    name: "twelve",
    sections: ["orbit", "neutron", "photon", "proton"],
  },
];

export const isAuth = async () => {
  try {
    const res = await axios.get(`${server_url}/api/isUserAuth`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    if (res.data.auth) {
      return true;
    } else {
      console.log(res);
      return false;
    }
  } catch (error) {
    console.dir(error);
    return false;
  }
};

export const getUser = async () => {
  try {
    const res = await axios.get(`${server_url}/api/isUserAuth`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    if (res.data.auth) {
      // console.log(res.data.user_data);

      return res.data.user_data;
    }
  } catch (error) {
    console.dir(error);
  }
};

export const logout = () => {
  localStorage.removeItem("token");
};
